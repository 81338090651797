<template>
  <div class="card card-custom gutter-b">
    <div class="card-header">
      <h4 class="mt-8 title-card-custom">Daftar Produk Hukum</h4>
    </div>
    <div class="d-flex justify-content-center">
      <ClipLoader
        :loading="this.loading"
        class="custom-class"
        color="#18328d"
        size="150"
        sizeUnit="px"
      />
    </div>
    <div v-if="this.dokumentasis.length !== 0">
      <div class="card-body" id="dokumentasi">
        <div v-for="dokumentasi in dokumentasis" :key="dokumentasi.id">
          <div class="d-flex">
            <div class="flex-grow-1">
              <!--begin: Title-->
              <div
                class="d-flex align-items-center justify-content-between flex-wrap"
              >
                <div class="mr-3">
                  <div class="d-flex flex-wrap my-2">
                    <span class="text-muted font-weight-bold mr-1">
                      <i
                        class="flaticon2-check-mark text-success icon-md mr-5"
                      ></i
                      >{{ dokumentasi.jenis_perundangan.name }}</span
                    >
                    <!--                    <a
                      href="#"
                      class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                      ><span class="mr-1">
                        <i class="flaticon2-line text-dark icon-md ml-2"></i>
                      </span>
                      {{ dokumentasi.judul }}</a
                    >
                                        <a
                      href="#"
                      class="text-muted text-hover-primary font-weight-bold"
                    >
                      <span class="mr-1">
                        <i
                          class="flaticon2-correct text-success icon-md ml-2"
                        ></i></span
                      >Penetapan Usulan</a
                    >-->
                  </div>
                  <!--end::Contacts-->
                </div>
              </div>
              <!--end: Title-->
              <!--begin: Content-->
              <div
                class="d-flex align-items-center flex-wrap justify-content-between"
              >
                <div
                  style="font-size:16px"
                  class="flex-grow-1 font-weight-bold py-5 py-lg-2 mr-5"
                >
                  <br />
                  {{ dokumentasi.judul }}
                </div>
                <div class="my-lg-0 my-1">
                  <button class="btn btn-download" @click="btnDownload">
                    Download
                  </button>
                </div>
              </div>
              <!--end: Content-->
            </div>
            <!--end: Info-->
          </div>
          <!--begin: Items-->
          <div class="d-flex align-items-center flex-wrap">
            <!--begin: Item-->
            <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
              <span></span>
              <div class="d-flex flex-column text-dark-50">
                <span class="font-weight-bolder font-size-sm">{{
                  moment(dokumentasi.created_at).format("DD MMMM YYYY")
                }}</span>
              </div>
            </div>
            <!--end: Item-->
            <!--begin: Item-->
            <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
              <span class="mr-4">
                <i class="flaticon-eye icon-2x text-muted font-weight-bold"></i>
              </span>
              <div class="d-flex flex-column text-dark-75">
                <span class="font-weight-bolder font-size-sm">{{ dokumentasi.total_view }}</span>
              </div>
            </div>
            <!--end: Item-->
            <!--begin: Item-->
            <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
              <span class="mr-4">
                <i class="fa fa-download text-muted"></i>
              </span>
              <div class="d-flex flex-column text-dark-75">
                <span class="font-weight-bolder font-size-sm">{{ dokumentasi.total_download }}</span>
              </div>
            </div>
            <!--end: Item-->
            <!--begin: Item-->
            <!--            <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
              <span class="mr-4">
                <i
                  class="flaticon-share icon-2x text-muted font-weight-bold"
                ></i>
              </span>
              <div class="d-flex flex-column flex-lg-fill">
                <span class="text-dark-75 font-weight-bolder font-size-sm"
                  >Bagikan</span
                >
              </div>
            </div>-->
            <!--end: Item-->
            <!--begin: Item-->
            <div class="d-flex align-items-center flex-lg-fill mr-5 my-1"></div>
            <!--end: Item-->
            <!--begin: Item-->
            <div class="d-flex align-items-center flex-lg-fill my-1"></div>
            <!--end: Item-->
          </div>
          <div class="separator separator-solid my-7"></div>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
          aria-controls="dokumentasi"
        ></b-pagination>
      </div>
    </div>
    <div class="card-body" v-else>
      <h5 class="text-center">Pencarian tidak ditemukan</h5>
      <div
        class="d-flex justify-content-lg-center justify-content-sm-center"
        style="height: auto"
      >
        <img class="mt-8" alt="Logo" :src="backgroundImage" height="300px" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { ClipLoader } from "@saeris/vue-spinners";

export default {
  name: "ListDokumentasi",
  components: {
    ClipLoader
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      dokumentasi: null,
      dokumentasis: [],
      loading: true
    };
  },
  computed: {
    rows() {
      return this.dokumentasis.length;
    },
    backgroundImage() {
      return process.env.BASE_URL + "media/custom/undraw_no_data.png";
    }
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_URL_LOCAL +
          `/dokumentasi?judul=` +
          this.$route.params.result
      )
      .then(items => {
        this.dokumentasis = items.data.data;
        this.loading = false;
      });
    /*axios.get(process.env.VUE_APP_URL_LOCAL + `/dokumentasi`).then(items => {
      this.dokumentasis = items.data.data;
    });*/
  },
  methods: {
    moment: function(date) {
      return moment(date);
    },
    btnDownload() {
      this.$router.push({
        name: "DetailDokumentasiList",
        params: this.dokumentasis[0]
      });
    }
  }
};
</script>

<style scoped></style>
